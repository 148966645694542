// extracted by mini-css-extract-plugin
var _1 = "BoardActions_action__4ShVr";
var _2 = "BoardActions_actionRightFirst__M9cxM";
var _3 = "BoardActions_actionRightLast__sLNN5";
var _4 = "BoardActions_active__y6+4Q";
var _5 = "BoardActions_cardsCount__xFf8S";
var _6 = "BoardActions_githubAction__MbwzQ";
var _7 = "BoardActions_githubGreen__HjN62";
var _8 = "BoardActions_githubGrey__8XjmR";
var _9 = "BoardActions_switchViewButton__kCknZ";
var _a = "BoardActions_title__jMP-z";
var _b = "BoardActions_wrapper__rxxRn";
export { _1 as "action", _2 as "actionRightFirst", _3 as "actionRightLast", _4 as "active", _5 as "cardsCount", _6 as "githubAction", _7 as "githubGreen", _8 as "githubGrey", _9 as "switchViewButton", _a as "title", _b as "wrapper" }
