// extracted by mini-css-extract-plugin
var _1 = "CardModal_addAttachmentButton__3gsbJ";
var _2 = "CardModal_alignCenter__dS75F";
var _3 = "CardModal_buttonToggle__ryXpA";
var _4 = "CardModal_cursorPointer__t6fNr";
var _5 = "CardModal_descriptionButton__-zAfk";
var _6 = "CardModal_descriptionButtonText__EXASW";
var _7 = "CardModal_descriptionText__r57zS";
var _8 = "CardModal_dropdownMenu__R9hNE";
var _9 = "CardModal_flexContainer__oei-z";
var _a = "CardModal_header__+Nab2";
var _b = "CardModal_headerCount__wEHB1";
var _c = "CardModal_headerFirstLine__6f999";
var _d = "CardModal_headerItem__AV22k";
var _e = "CardModal_headerItems__Yj6FN";
var _f = "CardModal_headerListField__f5TBr";
var _10 = "CardModal_headerListFieldIcon__ziRgG";
var _11 = "CardModal_headerListFieldWrapper__Qgk2U";
var _12 = "CardModal_headerTitle__uDXT2";
var _13 = "CardModal_headerTitleWrapper__SrbnX";
var _14 = "CardModal_hint__WEYmQ";
var _15 = "CardModal_hr__dgNgI";
var _16 = "CardModal_iconAddButton__ridYu";
var _17 = "CardModal_iconAddButton2__JHoDS";
var _18 = "CardModal_lastUser__fWOfZ";
var _19 = "CardModal_localChangesLoaded__4o8U5";
var _1a = "CardModal_mainContainer__dQo+b";
var _1b = "CardModal_moduleContainer__D5XN2";
var _1c = "CardModal_moduleHeader__b0c7q";
var _1d = "CardModal_moduleIcon__KX6oY";
var _1e = "CardModal_moreUsers__bHCdW";
var _1f = "CardModal_popupWrapper__v8i66";
var _20 = "CardModal_subscribeButton__p2EX5";
var _21 = "CardModal_taskDueDateSummaryWrapper__cQBbr";
var _22 = "CardModal_text__SCB7P";
var _23 = "CardModal_user__2b1tm";
var _24 = "CardModal_wrapper__sYgdA";
export { _1 as "addAttachmentButton", _2 as "alignCenter", _3 as "buttonToggle", _4 as "cursorPointer", _5 as "descriptionButton", _6 as "descriptionButtonText", _7 as "descriptionText", _8 as "dropdownMenu", _9 as "flexContainer", _a as "header", _b as "headerCount", _c as "headerFirstLine", _d as "headerItem", _e as "headerItems", _f as "headerListField", _10 as "headerListFieldIcon", _11 as "headerListFieldWrapper", _12 as "headerTitle", _13 as "headerTitleWrapper", _14 as "hint", _15 as "hr", _16 as "iconAddButton", _17 as "iconAddButton2", _18 as "lastUser", _19 as "localChangesLoaded", _1a as "mainContainer", _1b as "moduleContainer", _1c as "moduleHeader", _1d as "moduleIcon", _1e as "moreUsers", _1f as "popupWrapper", _20 as "subscribeButton", _21 as "taskDueDateSummaryWrapper", _22 as "text", _23 as "user", _24 as "wrapper" }
