// extracted by mini-css-extract-plugin
var _1 = "Board_addListButton__e+3yA";
var _2 = "Board_addListButtonIcon__nfSHM";
var _3 = "Board_addListButtonText__o-FjU";
var _4 = "Board_boardContainer__0Pvtq";
var _5 = "Board_boardWrapper__JrIK-";
var _6 = "Board_list__VkZpM";
var _7 = "Board_listWrapper__CfYqI";
var _8 = "Board_lists__gV2Uz";
var _9 = "Board_mainWrapper__B6nE9";
export { _1 as "addListButton", _2 as "addListButtonIcon", _3 as "addListButtonText", _4 as "boardContainer", _5 as "boardWrapper", _6 as "list", _7 as "listWrapper", _8 as "lists", _9 as "mainWrapper" }
